@import "../../styles/main.scss";

@mixin mobile-enterprise-form {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin compressed-contact-us-view {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

.emp-contact-us-page {
  display: flex;
  flex: 1;
  background: linear-gradient(101deg, #f1f4f9 0%, rgba(241, 244, 249, 0) 100%);
  justify-content: center;
  background-size: cover;


  section {
    max-width: 1200px;
    width: 100%;

    flex: 1;
    display: flex;
    padding: 0 30px;
    align-items: center;

    @include mobile-enterprise-form {
      padding: 0 18px;
    }

    .section-wrapper {
      padding-top: 80px;
      display: flex;
      height: fit-content;

      @include compressed-contact-us-view {
        flex-direction: column;
      }
    }

    .content-section {
      flex: 1;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      padding-top: 50px;

      @include compressed-contact-us-view {
        padding-right: 0px;
        text-align: center;
      }

      .header {
        font-size: 1.8em;
        line-height: 1.4em;
        color: $color-scale-gray-750;
        margin: 0;
      }

      .description {
        margin-top: 16px;
        line-height: 1.4em;
        color: $color-scale-gray-600;
      }
    }

    .form-section {
      min-width: 400px;
      max-width: 400px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile-enterprise-form {
        min-width: 0;
      }

      @include compressed-contact-us-view {
        padding-left: 0px;
        max-width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;
      }

      .form-card {
        width: 100%;
        border-radius: 20px;
        padding: 30px;
        background: rgba(255, 255, 255, 0.5);
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);
        border: 1px solid $color-scale-gray-150;


        @include compressed-contact-us-view {
          max-width: 400px;
        }

        @include mobile-enterprise-form {
          padding: 30px 20px;
          border-radius: 12px;
        }

        .header {
          margin: 0;
          font-weight: 600;
          color: $color-scale-gray-750;
        }

        .description {
          font-size: 0.9em;
          line-height: 1.4em;
          font-weight: 500;
          color: $color-scale-gray-600;
        }

        .form-row {
          width: 100%;
          display: flex;
          margin-top: 40px;

          .left-group {
            flex: 1;
            padding-right: 12px;

            @include mobile-enterprise-form {
              padding-right: 0px;
            }
          }

          .right-group {
            flex: 1;
            padding-left: 12px;

            @include mobile-enterprise-form {
              padding-left: 0px;
            }
          }

          @include mobile-enterprise-form {
            flex-direction: column;
          }
        }

        .form-btn-row {
          display: flex;
          justify-content: flex-end;
          width: 100%;

          .btn-width-wrapper {
            width: fit-content;

            @include mobile-enterprise-form {
              width: 100%;
            }
          }
        }

        .contact-us-feedback-section {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 100%;

          .check-btn {
            width: 80px;
            height: auto;
            margin: 20px 0 20px 0;
          }

          .feedback-message {
            width: 100%;
            font-size: 0.9em;
            line-height: 1.4em;
            color: $color-scale-gray-500;
          }
        }
      }
    }
  }
}