@import "../../../styles/main.scss";


.emp-toast {

    border-radius: 100px;
    padding: 10px;
    padding-right: 18px;
    color: white;
    background-color: map-get($color-scale-gray, 850);
    border: 1px solid map-get($color-scale-gray, 800);
    margin-top: 10px;
    opacity: 0;
    transform: translateX(100%);
    transition: 300ms all;

    &.appear {
        opacity: 1;
        transform: translateX(0%);
    }

    .toast-wrapper {
        display: flex;
        align-items: center;
        // background-color: red;

        .icon-wrapper {
            flex-shrink: 0;
            width: 38px;
            height: 38px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.success {
                background-color: rgba(22, 128, 57, 0.1);
            }

            &.error {
                background-color: rgba(255, 29, 37, 0.1);
            }
        }

        .title {
            position: relative;
            top: 2px;
            margin: 0;
            margin-top: 2px;
            font-weight: 600;
            font-size: 0.9em;
        }

        .description {
            margin: 0;
            margin-top: 4px;
            color: map-get($color-scale-gray, 500);
            font-weight: 500;
            font-size: 0.8em;
            line-height: 1.4em;
        }

    }
}