@import "../../../styles/main.scss";

.btn-wrapper {
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover {
        .bar {
            background-color: map-get($color-scale-gray, 0);
        }
    }

    .top {
        transform: rotate(45deg);
        transform-origin: 0% 0%;
    }

    .middle {
        transform: scaleX(0);
    }

    .bottom {
        transform: rotate(-45deg);
        transform-origin: 0% 50%;
    }


    .bar {
        height: 2px;
        width: 20px;
        background-color: map-get($color-scale-gray, 350);
        border-radius: 10px;
        transition: 300ms all;

        &:not(:first-child) {
            margin-top: 5px;
        }
    }
}