@import "breakpoint";
@import "colors";
@import "spacing";
@import "font";
@import "display";
@import "table";
@import "card";
@import "form";
@import "loader";
@import "typography";

hr {
  height: 1px;
  border: none;
  background-color: $color-scale-gray-0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 1024px) {

    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

* {
  box-sizing: border-box;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.app-page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.grecaptcha-badge {
  visibility: hidden;
}

.emp-reset {
  all: unset
}