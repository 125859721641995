.block {
    display: block;
}

.inline {
    display: inline;
}

.flex {
    display: flex;
}

.none {
    display: none;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.h-stack {
    display: flex;
    flex-direction: row;
}

.v-stack {
    display: flex;
    flex-direction: column;
}