@import "../../../styles/main.scss";


.emp-select-wrapper {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid transparent;

    label {
        font-size: 1em;
        font-weight: 500;
        color: map-get($color-scale-gray, 750);

        .required {
            display: inline;
            color: red;
        }
    }

    .emp-form-control {
        position: relative;
    }

    .emp-select-icon {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .emp-text-input {
        all: unset;
        background-color: map-get($color-scale-gray , 0);
        border-radius: 6px;
        padding: 9px 12px;
        color: map-get($color-scale-gray, 900);
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        box-shadow: 0 0 0 1px map-get($color-scale-gray , 100);
        transition: all 200ms;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 38px;

        &.disabled {
            background-color: map-get($color-scale-gray , 50);
        }


        @include breakpoint(mobile) {
            height: 45px;
        }

        &:focus {
            box-shadow: 0 0 0 1px map-get($color-scale-primary , 600);
        }



        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: map-get($color-scale-gray, 300);
            font-weight: 400;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: map-get($color-scale-gray, 300);
            font-weight: 400;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: map-get($color-scale-gray, 300);
            font-weight: 400;
        }
    }

    select:invalid,
    select option[value=""] {
        color: map-get($color-scale-gray, 300) !important;
        font-weight: 400 !important;
    }

    p {
        font-size: 0.95em;
        color: map-get($color-scale-gray, 500);
        line-height: 1.4em;
        margin: 0;
        margin-bottom: 8px;
    }
}