.emrg-table {
    width: 100%;

    thead {
        background-color: map-get($color-scale-gray, 1000);
    }

    td,
    th {
        padding: 12px 10px;
        vertical-align: text-top;
    }

    th {
        color: map-get($color-scale-gray, 500);
        &:first-child{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        &:last-child{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    
    td{
        color: map-get($color-scale-gray, 300);
        border-bottom: 1px solid map-get($color-scale-gray, 900);
    }
}