// Color scales

$gray-card-alt: #282b31;
$color-scale-primary: (
  100: #e9f2fe,
  150: #d3e4fd,
  200: #bed7fc,
  250: #a8c9fb,
  300: #92bcfb,
  350: #7caefa,
  400: #67a1f9,
  450: #5193f8,
  500: #3b86f7,
  550: #1a72f6,
  600: #0961e5,
  650: #0853c4,
  700: #0745a3,
  750: #053783,
  800: #042a62,
  850: #031c41,
  900: #010e21,
);

$color-scale-red: (
  100: #ffd2d3,
  200: #ffa5a7,
  300: #ff777b,
  400: #ff4a4f,
  500: #ff1d23,
  600: #d50006,
  700: #8e0004,
  800: #470002,
);

$color-scale-green: (
  100: #c2f4d2,
  200: #84eaa6,
  300: #47df79,
  400: #21bd54,
  500: #168039,
  600: #11602b,
  700: #0b401d,
  800: #06200e,
);

$color-scale-gray: (
  0: #fefefe,
  50: #f8f8f9,
  100: #eff0f1,
  150: #dfe0e3,
  200: #cfd1d6,
  250: #bfc2c8,
  300: #aeb3ba,
  350: #9ea3ac,
  400: #8e949f,
  450: #7e8591,
  500: #6f7682,
  550: #595e68,
  600: #4e535b,
  650: #43474e,
  700: #383b41,
  750: #2c2f34,
  800: #212327,
  850: #16181a,
  900: #0b0c0d,
  950: #010e21,
  1000: #010e21,
);

$color-scale-gray-0: map-get($color-scale-gray, 0);
$color-scale-gray-50: map-get($color-scale-gray, 50);
$color-scale-gray-100: map-get($color-scale-gray, 100);
$color-scale-gray-150: map-get($color-scale-gray, 150);
$color-scale-gray-200: map-get($color-scale-gray, 200);
$color-scale-gray-250: map-get($color-scale-gray, 250);
$color-scale-gray-300: map-get($color-scale-gray, 300);
$color-scale-gray-350: map-get($color-scale-gray, 350);
$color-scale-gray-400: map-get($color-scale-gray, 400);
$color-scale-gray-450: map-get($color-scale-gray, 450);
$color-scale-gray-500: map-get($color-scale-gray, 500);
$color-scale-gray-550: map-get($color-scale-gray, 550);
$color-scale-gray-600: map-get($color-scale-gray, 600);
$color-scale-gray-650: map-get($color-scale-gray, 650);
$color-scale-gray-700: map-get($color-scale-gray, 700);
$color-scale-gray-750: map-get($color-scale-gray, 750);
$color-scale-gray-800: map-get($color-scale-gray, 800);
$color-scale-gray-850: map-get($color-scale-gray, 850);
$color-scale-gray-900: map-get($color-scale-gray, 900);
$color-scale-gray-950: map-get($color-scale-gray, 950);
$color-scale-gray-1000: map-get($color-scale-gray, 1000);

$color-scale-primary-0: map-get($color-scale-primary, 0);
$color-scale-primary-50: map-get($color-scale-primary, 50);
$color-scale-primary-100: map-get($color-scale-primary, 100);
$color-scale-primary-150: map-get($color-scale-primary, 150);
$color-scale-primary-200: map-get($color-scale-primary, 200);
$color-scale-primary-250: map-get($color-scale-primary, 250);
$color-scale-primary-300: map-get($color-scale-primary, 300);
$color-scale-primary-350: map-get($color-scale-primary, 350);
$color-scale-primary-400: map-get($color-scale-primary, 400);
$color-scale-primary-450: map-get($color-scale-primary, 450);
$color-scale-primary-500: map-get($color-scale-primary, 500);
$color-scale-primary-550: map-get($color-scale-primary, 550);
$color-scale-primary-600: map-get($color-scale-primary, 600);
$color-scale-primary-650: map-get($color-scale-primary, 650);
$color-scale-primary-700: map-get($color-scale-primary, 700);
$color-scale-primary-750: map-get($color-scale-primary, 750);
$color-scale-primary-800: map-get($color-scale-primary, 800);
$color-scale-primary-850: map-get($color-scale-primary, 850);
$color-scale-primary-900: map-get($color-scale-primary, 900);
$color-scale-primary-950: map-get($color-scale-primary, 950);
$color-scale-primary-1000: map-get($color-scale-primary, 1000);

$color-scale-danger: (
  0: #fdd9d7,
  250: #f88e86,
  500: #f44336,
  750: #922820,
  1000: #310d0b,
);

$chart-color: (
  "purple": #a158ff,
  "purple-light": rgba(160, 88, 255, 0.2),
  "orange": #ff9458,
  "orange-light": rgba(255, 149, 88, 0.2),
  "teal": #58b7ff,
  "teal-light": rgba(88, 183, 255, 0.2),
);

// gradients
$color-gradient: (
  0: #0295ff,
  100: #0057ff,
);

@each $name,
$scale in (primary: $color-scale-primary,
  gray: $color-scale-gray,
  danger: $color-scale-danger,
  chart: $chart-color ) {
  @each $shade, $color in $scale {
    .bg-#{$name}-#{$shade} {
      background-color: $color;
    }

    .color-#{$name}-#{$shade} {
      color: $color;
    }
  }
}

$main-color: map-get($color-scale-primary, 950);