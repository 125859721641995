@import "../../../styles/main.scss";


.emp-input-wrapper {
    width: 100%;
    margin-bottom: 10px;

    label {
        display: flex;
        align-items: center;
        font-size: 0.9em;
        font-weight: 500;
        color: map-get($color-scale-gray, 750);

        &.compressed {
            margin-bottom: 2px;
            font-weight: 700;
            font-size: 0.85em;
            color: map-get($color-scale-gray, 400);
        }


        .required {
            display: inline;
            color: red;
        }

        .optional {
            display: inline-block;
            margin-left: 4px;
            font-size: 0.9em;
            font-weight: 600;
            color: darkgray;
        }
    }


    .emp-text-input-wrapper {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid map-get($color-scale-gray , 100);
        background-color: map-get($color-scale-gray , 0);
        transition: border 200ms;

        &.disabled {
            background-color: map-get($color-scale-gray , 50);
        }

        &.focused {
            border: 1px solid map-get($color-scale-primary , 550);
        }

        .emp-left-icon-wrapper {
            padding: 0 6px 0 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px 0 0 6px;
            border-right: none;
            height: 38px;
            box-sizing: border-box;
        }

        .emp-right-icon-wrapper {
            padding: 0 9px 0 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 6px 6px 0px;
            border-right: none;
            height: 38px;
            box-sizing: border-box;
        }

        .emp-text-input {
            all: unset;
            border-radius: 6px;
            padding: 9px 12px;
            color: map-get($color-scale-gray, 900);
            font-weight: 500;
            font-size: 14px;
            width: 100%;
            line-height: 1.4em;
            transition: all 200ms;
            box-sizing: border-box;
            height: 38px;

            @include breakpoint(mobile) {
                height: 45px;
                font-size: 15px;
            }

            &.left-icon {
                padding-left: 0;
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: map-get($color-scale-gray, 300);
                font-weight: 400;
                opacity: 1;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: map-get($color-scale-gray, 300);
                font-weight: 400;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: map-get($color-scale-gray, 300);
                font-weight: 400;
            }
        }

    }

    .actions-wrapper {
        display: flex;
        align-items: center;
    }

    .emp-error-wrapper {
        flex: 1;
    }

    .right-elems {
        flex-grow: 1;
    }

    .emp-text-input-show-password-wrapper,
    .emp-text-input-character-count-wrapper {
        display: flex;
        justify-content: flex-end;
        padding: 0 4px;
        box-sizing: border-box;

        .emp-text-input-show-password {
            cursor: pointer;
            font-weight: 600;
            font-size: 0.95em;
            display: block;
            margin-top: 6px;
            color: map-get($color-scale-primary, 600);
            transition: all 300ms;

            &:hover {
                color: map-get($color-scale-primary, 800);
            }
        }

        .emp-text-input-character-count {
            display: block;
            margin-top: 4px;
            color: map-get($color-scale-gray, 400);
            font-size: 0.95em;
            font-weight: 500;
        }
    }

    p {
        font-size: 0.95em;
        color: map-get($color-scale-gray, 500);
        line-height: 1.4em;
        margin: 0;
        margin-bottom: 8px;
    }
}