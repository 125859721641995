@import "../../styles/main.scss";

.vendor-logo {
  height: 40px;
}

.emp-waitlist-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 0 0px;
  flex: 1;

  .intro-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: $color-scale-gray-0;
    width: 100%;
    height: 300px;
    padding: 0 20px;

    text-align: center;

    .header-background {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      opacity: 0.4;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        position: relative;

        @include breakpoint(mobile) {
          transform: scale(200%);
          top: 0;
        }
      }
    }

    .content-section {
      z-index: 3;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        color: $color-scale-gray-100;
        font-size: 1.6em;

        @include breakpoint(mobile) {
          font-size: 1.4em;
        }
      }

      .description {
        line-height: 1.4em;
        color: $color-scale-gray-300;
        margin: 0;
      }
    }
  }

  .waitlist-content {
    max-width: 1000px;
    width: 100%;
    padding: 20px 0 0 0;

    @include breakpoint(mobile) {
      padding-top: 40px;
    }

    .cards-section {
      width: 100%;
      padding: 15px 15px 40px 15px;

      border-radius: 12px;

      @include breakpoint(mobile) {
        padding: 15px 15px 40px 15px;
      }

      .section-title {
        margin: 0;
        font-size: 1.7em;
        font-weight: 700;
        color: $color-scale-gray-100;
        background: linear-gradient(0deg, #a1efff 0%, #bac4ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include breakpoint(mobile) {
          font-size: 1.4em;
          text-align: center;
        }
      }

      .intro-description {
        font-size: 1em;
        color: $color-scale-gray-300;
        line-height: 1.4em;

        @include breakpoint(mobile) {
          margin-top: 10px;
          margin-bottom: 30px;
          text-align: center;
        }
      }

      .mobile-wrapper {
        display: none;

        @include breakpoint(mobile) {
          display: block;
        }
      }

      .desktop-wrapper {
        @include breakpoint(mobile) {
          display: none;
        }

        .card-wrapper {
          margin: 0;
          margin-top: 70px;
          display: flex;
          gap: 20px;
        }

        .card-col {
          flex: 1;

          &.offset {
            margin-top: 80px;
          }
        }
      }

      .feature-card {
        transition: all 300ms;
        transform: translateY(0px);

        .window {
          transition: all 300ms;
          filter: grayscale(100%) !important;

          img {
            transform: translateY(20px);
            position: relative;
            transition: all 300ms;
          }
        }

        &.added,
        &:hover {
          transform: translateY(-10px);

          .window {
            filter: grayscale(0%) !important;
          }

          img {
            transform: translateY(0px);
          }

          .card-title {
            background: linear-gradient(0deg, #e6d4ff 0%, #b7baff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .feature-card {
        background-color: $color-scale-gray-850;
        padding: 12px;
        border-radius: 12px;

        @include breakpoint(mobile) {
          padding: 6px;
        }

        .window {
          width: 100%;
          background: #474bff;
          background: -webkit-linear-gradient(0deg, #474bff 0%, #bc48ff 100%);
          background: linear-gradient(0deg, #474bff 0%, #bc48ff 100%);
          border-radius: 8px;
          padding: 10px;

          overflow: hidden;
          aspect-ratio: 1.8;

          .task-summary-image {
            width: 100%;
          }
        }

        .card-info-section {
          margin-top: 12px;

          padding: 0 6px 8px 4px;

          .card-title {
            font-weight: 600;
            color: $color-scale-gray-150;
            font-size: 1.1em;
            line-height: 1.4em;

            @include breakpoint(mobile) {
              font-size: 0.95em;
            }
          }

          .card-description {
            margin: 0;
            margin-top: 8px;
            line-height: 1.4em;
            color: $color-scale-gray-300;
            font-size: 1em;

            @include breakpoint(mobile) {
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }

  .waitlist-form-section {
    width: 100%;
    padding: 20px 15px 10px 15px;
    background-color: $color-scale-gray-850;
    display: flex;
    justify-content: center;

    .waitlist-form-wrapper {
      width: 100%;
      max-width: 500px;
      display: flex;
      flex-direction: column;

      @include breakpoint(mobile) {
        padding: 0 20px;
      }
    }

    p {
      text-align: center;
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 1.4em;
      font-size: 0.9em;
      color: $color-scale-gray-400;
    }

    h1 {
      text-align: center;
      color: $color-scale-gray-100;
      font-size: 1.3em;
    }

    .bundle-form-control-wrapper {
      display: flex;
      gap: 12px;

      @include breakpoint(mobile) {
        flex-direction: column;
        gap: 0;
      }
    }
  }

  .completed-content {
    flex: 1;

    .content-section {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      height: 100%;
      padding: 0 30px;

      .content-wrapper {
        position: relative;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .check-btn {
          width: 80px;
          height: auto;
          margin: 20px 0 20px 0;
        }

        .title {
          margin: 0;
          font-size: 1.1em;
          line-height: 1.4em;
          font-weight: 600;
          color: $color-scale-gray-200;
        }

        .feedback-message {
          margin: 0;
          margin-top: 6px;
          width: 100%;
          font-size: 0.9em;
          line-height: 1.4em;
          color: $color-scale-gray-400;
        }
      }
    }
  }
}
