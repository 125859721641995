@import "../../../styles/main.scss";

@mixin mobile-nav {
  @media only screen and (max-width: 700px) {
    @content;
  }
}

.emp-footer {
  background-color: map-get($color-scale-gray, 0);
  width: 100%;
  display: flex;
  justify-content: center;
  color: map-get($color-scale-gray, 800);
  position: relative;
  border-top: solid 1px $color-scale-gray-100;

  section {
    max-width: 1200px;
    width: 1200px;

    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 10px 0;
      font-size: 16px;
    }

    @include breakpoint(small) {
      width: 100%;
    }

    @include breakpoint(mobile-sm) {
      justify-content: center;
      flex-direction: column;
    }

    .footer-section {
      &-top {
        width: 100%;
        flex: 1;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 8px;
        margin-bottom: 16px;

        @include breakpoint(mobile) {
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }

        div {
          margin: 0;
          list-style: none;
          padding: 0;
          display: flex;
          justify-content: center;

          @include breakpoint(mobile-sm) {
            flex-direction: column;
            align-items: center;
            gap: 10px;
          }

          nav {
            &:not(last-child) {
              ul {
                border-right: solid 1px $color-scale-gray-150;

                @include breakpoint(mobile-sm) {
                  border-right: none;
                }
              }
            }

            ul {
              margin-top: 0;
              margin-bottom: 0;
              padding-left: 0px;
              padding-right: 30px;
              margin-right: 30px;
              list-style: none;



              @include breakpoint(mobile_med) {
                padding-right: 12px;
                margin-right: 12px;
              }

              @include breakpoint(mobile-sm) {
                width: 100%;
                padding-bottom: 14px;
                margin-bottom: 4px;
                margin-right: 0;
                padding-right: 0;
                border-bottom: solid 1px map-get($color-scale-gray, 200);
                border-right: none;
              }

              li {
                display: flex;
                padding: 0 4px;
                flex-direction: column;
                gap: 6px;

                a {
                  display: inline-block;
                  font-weight: 500;
                  color: $color-scale-gray-600;
                  position: relative;
                  transition: color 0.2s ease-in-out;

                  @include breakpoint(mobile-sm) {
                    text-align: center;
                  }

                  span {
                    display: inline-block;
                    position: relative;
                    transition: transform 0.5s ease-in-out;
                  }

                  &:hover {
                    color: map-get($color-scale-gray, 850);
                  }
                }
              }
            }

            &:last-child {
              ul {
                margin-right: 0;
                padding-right: 0;
                border: none;
              }
            }
          }
        }
      }

      &-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 20px;

        @include breakpoint(mobile-sm) {
          flex-wrap: wrap-reverse;
          justify-content: center;
        }

        .copyright {
          color: $color-scale-gray-600;
          font-size: 0.9em;
          font-weight: 500;
        }

        .footer-socials {
          display: flex;
          gap: 4px;
          align-items: center;

          .social-icons {
            svg {
              &:hover {
                transform: translateY(-2px);
                opacity: 0.8;
                transition: all 0.5s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  .logo-wrapper {
    flex: none;
    cursor: pointer;

    @include mobile-nav {
      flex: 1;
    }

    .logo {
      position: relative;
      height: 28px;

      @include breakpoint(mobile) {
        top: 2px;
        height: 24px;
      }
    }
  }
}