// Font imports
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

// Font variables
$font-family-base: "Poppins", sans-serif;

// Base typography
body {
  font-family: $font-family-base;
}

a {
  font-weight: 600;
  color: #5193f8;
  text-decoration: unset;

  &:active {
    color: #3b86f7;
  }
  &:hover {
    color: #3b86f7;
  }
}

.text-light {
  font-family: $font-family-base;
  font-weight: 300;
}

.text-normal {
  font-family: $font-family-base;
  font-weight: 400;
}

.text-medium {
  font-family: $font-family-base;
  font-weight: 500;
}

.text-bold {
  font-family: $font-family-base;
  font-weight: 600;
}

.text-bolder {
  font-family: $font-family-base;
  font-weight: 900;
}

.subtitle {
  font-size: 13px;
}
