@import "../../../styles/main.scss";

@mixin mobile-nav {
  @media only screen and (max-width: 1110px) {
    @content;
  }
}

.emrg-navbar {
  background: #fefefe;
  border-bottom: 1px solid $color-scale-gray-100;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
  width: 100%;
  z-index: 5;

  .navbar-content {
    width: 1200px;
    padding: 0 30px;

    .button-wrapper {
      display: flex;
      gap: 8px;
    }

    @include breakpoint(small) {
      width: 100%;
    }

    @include breakpoint(mobile) {
      padding: 0 15px;
    }

    max-width: 1200px;
    display: flex;
    height: 65px;
    align-items: center;
    justify-content: space-between;

    .menu-btn-wrapper {
      display: none;

      @include mobile-nav {
        display: block;
      }
    }



    .logo-wrapper {
      flex: none;
      cursor: pointer;

      @include mobile-nav {
        flex: 1;
      }

      .logo {
        position: relative;
        height: 28px;

        .s1 {
          stroke: #010e21;
          stroke-width: 2;
          fill: transparent;
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: s1DrawLine 5s ease forwards;
        }

        @include breakpoint(mobile) {
          top: 2px;
          height: 24px;
        }

        @keyframes s1DrawLine {
          to {
            stroke-dashoffset: 0;
            fill: #010e21;
          }
        }
      }
    }

    ul {
      margin: 0;
      height: 100%;
      display: flex;
      padding: 0;

      @include mobile-nav {
        display: none;
      }

      .menu-item-wrapper {
        height: 100%;

        .menu-item {
          height: 100%;
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          z-index: 1;
          transition: 200ms all;

          .popover-wrapper {
            transition: all 200ms;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            width: fit-content;
            bottom: 0;
            left: 0;
            transform: scale(0.95) translateY(100%);
            z-index: -10;

            &.visible {
              visibility: visible;
              z-index: 1;
              opacity: 1;
              transform: scale(1) translateY(100%);
            }
          }

          &.active {
            .hover-bar {
              height: 4px;
              display: flex;
            }

            .text-div {
              span {
                color: $color-scale-gray-800;
              }
            }
          }

          .hover-bar {
            position: absolute;
            left: 0;
            bottom: 0;
            display: none;
            height: 0px;
            justify-content: center;
            overflow: hidden;
            height: 4px;
            width: 100%;
            transition: all 200ms;

            .bar {
              height: 100%;
              width: 90%;
              background-color: #1a72f6;
            }
          }

          &:hover {
            .text-div {
              span {
                color: $color-scale-gray-800;
              }
            }
          }

          &::before {
            content: "";
            opacity: 0;
            position: absolute;
            bottom: -42px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 20px;
            aspect-ratio: 1/1;
            border-radius: 4px;
            background-color: #fefefe;
            transform: rotate(-45deg);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 0);
          }

          .resources-popover {
            padding: 3px 5px;
            background-color: $color-scale-gray-0;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 12px;
            z-index: 10;

            .popover-items-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 16px;
              background-color: #fefefe;
              border-radius: 11px;
              transition: all 0.2s ease-in-out;

              &:hover {
                background-color: $color-scale-gray-100;
              }

              .popover-left-contain {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;

                .popover-icon-wrapper {
                  position: relative;
                  top: 2px;
                  align-self: flex-start;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 2px;
                }

                .popover-content-wrapper {
                  width: 250px;

                  .popover-title {
                    font-size: 0.95em;
                    font-weight: 600;
                    color: $color-scale-gray-700;
                    margin: 0;
                  }

                  .popover-subtitle {
                    margin: 0;
                    color: $color-scale-gray-500;
                    font-weight: 500;
                    font-size: 13px;
                  }
                }
              }

              .popover-chevron-icon-wrapper {
                svg {
                  transform: rotate(270deg);
                }
              }
            }
          }

        }
      }


      .text-div {
        display: flex;
        align-items: center;
        gap: 3px;
        z-index: 0;
        height: 40px;
        padding: 0 18px;


        .chevron-wrapper {
          transition: 200ms transform;

          &.active {
            transform: rotate(180deg);
          }
        }

        span {
          font-size: 16px;
          font-weight: 500;
          color: $color-scale-gray-600;
        }
      }
    }
  }

  .mobile-menu {
    width: 100%;
    height: 0px;
    padding: 0 15px;
    overflow: hidden;
    transition: 400ms all;

    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding: 0 15px;
        height: 45px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        transition: 200ms all;


        &:not(:first-child) {
          margin-top: 4px;
        }

        &:active {
          background-color: $color-scale-gray-150;

          span {
            color: map-get($color-scale-gray, 1000);
          }
        }

        span {
          color: map-get($color-scale-gray, 800);
          font-weight: 500;
          transition: 200ms all;
        }

        .chevron-wrapper {
          margin-left: 4px;
          transition: 200ms transform;

          &.active {
            transform: rotate(180deg);
          }
        }
      }

      .submenu-ul {
        list-style-type: none;
        overflow: hidden;
        padding: 0;
        height: 0;
        transition: all 200ms;


        li {
          padding: 0 15px 0 25px;
          height: 45px;
          display: flex;
          align-items: center;
          cursor: pointer;
          border-radius: 6px;
          transition: 200ms all;
          color: map-get($color-scale-gray, 600);
          font-weight: 500;

          &:active {
            background-color: $color-scale-gray-150;
            color: map-get($color-scale-gray, 1000) !important;
          }
        }
      }
    }
  }
}