.emp-toast-manager {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 50;
    max-width: 400px;

    .toast-wrapper {

        &:not(:first-child) {
            margin-top: 10px;
        }
    }
}