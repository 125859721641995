.emp-error-message-wrapper {
    display: flex;
    margin-top: 10px;

    span {
        display: block;
        margin-left: 6px;
        color: #FF4A4F;
        font-size: 0.8em;
        line-height: 1.3em;
    }
}