@import "../../styles/main.scss";
// NotFoundPage.scss

.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #fefefe;

  .logo {
    width: 100px;
    margin-bottom: 20px;
  }

  .content {
    max-width: 400px;
    margin: 20px;

    h1 {
      font-size: 2em;
      margin-bottom: 10px;
      color: #010e21;
    }

    p {
      margin-bottom: 20px;
      color: map-get($color-scale-gray, 800);
    }
    > div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 600px) {
  .not-found-container {
    .content {
      max-width: 100%;
    }
  }
}
