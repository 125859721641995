@import "../../../styles/main.scss";

.emp-button {
  all: unset;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 0 16px;
  color: map-get($color-scale-gray, 0);
  font-weight: 500;
  font-size: 0.9em;
  cursor: pointer;
  white-space: nowrap;
  background: linear-gradient(99.29deg, #4d9eff 16.32%, #1a78ff 74.05%);
  transition: all 200ms ease;
  text-align: center;

  border-radius: 8px;
  // mask-image: paint(squircle);
  --squircle-radius: 30px;
  --squircle-smooth: 4;

  &.height- {
    &sm {
      height: 30px;

      @include breakpoint(mobile) {
        height: 35px;
      }
    }
    &md {
      height: 35px;

      @include breakpoint(mobile) {
        height: 40px;
      }
    }
    &lg {
      height: 40px;

      @include breakpoint(mobile) {
        height: 45px;
      }
    }
    &xl {
      height: 45px;

      @include breakpoint(mobile) {
        height: 50px;
      }
    }
  }

  &.secondary {
    background: none;
    background-color: map-get($color-scale-gray, 100) !important;
    color: map-get($color-scale-gray, 800);

    &:hover {
      color: map-get($color-scale-gray, 800) !important;
      background-color: map-get($color-scale-gray, 150) !important;
    }
  }

  &.secondary-dark {
    background: none;
    background-color: map-get($color-scale-gray, 150) !important;
    color: map-get($color-scale-gray, 800);

    &:hover {
      color: map-get($color-scale-gray, 800) !important;
      background-color: map-get($color-scale-gray, 200) !important;
    }
  }

  &.danger {
    background: none;
    background-color: map-get($color-scale-red, 600) !important;
    color: map-get($color-scale-gray, 0);

    &:hover {
      background-color: map-get($color-scale-red, 700) !important;
      color: map-get($color-scale-gray, 0);
    }
  }

  &.success {
    background: none;
    background-color: map-get($color-scale-green, 500) !important;
    color: map-get($color-scale-gray, 0);

    &:hover {
      background-color: map-get($color-scale-green, 700) !important;
      color: map-get($color-scale-gray, 0);
    }
  }

  &:disabled,
  &[disabled] {
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: not-allowed;

    &:hover {
      background-color: #cccccc !important;
      color: #666666 !important;
    }
  }

  &:not(:first-child) {
    @include breakpoint(mobile) {
      margin-top: 12px;
    }
  }

  &.loading {
    background-color: map-get($color-scale-gray, 200);

    &:hover {
      background-color: map-get($color-scale-gray, 200);
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  .emp-button-content {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
  }

  .emp-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.full-width {
  width: 100%;
}

.fit-content {
  width: fit-content;
}
