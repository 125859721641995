@import "colors";

.emp-page-heading,
.emp-page-subtitle {
  margin: 0;
}

.emp-page-heading {
  color: map-get($color-scale-gray, 700);
  font-size: 3.6em;
  line-height: 1.1em;

  @include breakpoint(mobile) {
    font-size: 3em;
    display: block;
  }
}

.emp-page-subtitle {
  max-width: 700px;
  text-align: center;
  color: map-get($color-scale-gray, 500);
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 500;
}