.emrg-card {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  min-height: 140px;
  border-radius: 16px;
  background-color: map-get($color-scale-gray, 950);
  padding: 20px;
}

.emrg-demo-cards {
  width: 100%;
  overflow: hidden;

  .card-header {
    padding: 30px;
    h3 {
      color: map-get($color-scale-gray, 800);
      font-size: 1.5em;
      font-weight: 700;
      margin: 0;
      line-height: 1.4em;
    }

    p {
      font-size: 1em;
      line-height: 1.4em;
      margin-bottom: 0;
    }
  }
}
