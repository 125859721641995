.emp-spinner {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #16181A;
    border-right-color: #0961E5;
    animation: spinner 1s infinite;

    @keyframes spinner {
        to {
            transform: rotate(1turn);
        }
    }
}